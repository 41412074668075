<template>
	<div id="meeting">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>办公</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">我的会议</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="title u-f-item u-f-jsb">
					<div>
						共{{total}}条数据
					</div>
				</div>
				<el-table :data="list" height="84%" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
					<el-table-column prop="title" label="会议主题" align="center"></el-table-column>
					<el-table-column prop="start_time_text" label="开始时间" align="center"></el-table-column>
					<el-table-column prop="end_time_text" label="结束时间" align="center"></el-table-column>
					<el-table-column prop="place" label="会议地点" align="center"></el-table-column>
					<el-table-column prop="explain" label="会议内容" align="center"></el-table-column>
					<el-table-column label="参会人员" align="center">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" :content="scope.row.user_list_name.toString()" placement="top-start">
							  <div>{{scope.row.user_list_name.toString()}}</div>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right" style="padding: 15px 0;">
					<!-- <el-button type="danger" plain size="mini">删除</el-button> -->
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			list:[],
			total: 0,
			page: 1,
			limit: 13
		};
	},
	mounted() {
		this.getList()
	},
	methods: {
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getList()
		},
		getList(){
			this.$api.rule.meetingmyList({
				page:this.page,
				limit:this.limit
			}).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		}
	}
};
</script>

<style lang="scss">
#meeting {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		background-color: #ffffff;
		height: 85vh;
		margin-top: 20px;
		padding: 0 30px;
		.title {
			padding: 15px 0;
		}
	}
	
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
	
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
